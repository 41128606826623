// import "@fontsource/montserrat";
import Plausible from "plausible-tracker";

export const onClientEntry = () => {
  if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
    (window as any).plausible =
      (window as any).plausible ||
      Plausible({
        domain: "otthoeren.de",
        trackLocalhost: true,
        apiHost: process.env.PLAUSIBLE_API_URL,
      });
  } else {
    (window as any).plausible =
      (window as any).plausible ||
      Plausible({
        domain: "otthoeren.de",
        trackLocalhost: false,
        apiHost: process.env.PLAUSIBLE_API_URL,
      });
  }
};

export const onRouteUpdate = ({ location }) => {
  // const pathIsExcluded =
  //   location &&
  //   typeof (window as any).plausibleExcludePaths !== `undefined` &&
  //   (window as any).plausibleExcludePaths.some((rx) => rx.test(location.pathname));
  // if (pathIsExcluded) return null;
  (window as any).plausible.trackPageview();
};
