exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/blog-page.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-cancel-appointment-page-tsx": () => import("./../../../src/templates/cancel-appointment-page.tsx" /* webpackChunkName: "component---src-templates-cancel-appointment-page-tsx" */),
  "component---src-templates-career-page-tsx": () => import("./../../../src/templates/career-page.tsx" /* webpackChunkName: "component---src-templates-career-page-tsx" */),
  "component---src-templates-hearing-test-page-tsx": () => import("./../../../src/templates/hearing-test-page.tsx" /* webpackChunkName: "component---src-templates-hearing-test-page-tsx" */),
  "component---src-templates-hoergeraete-page-tsx": () => import("./../../../src/templates/hoergeraete-page.tsx" /* webpackChunkName: "component---src-templates-hoergeraete-page-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-make-appointment-page-tsx": () => import("./../../../src/templates/make-appointment-page.tsx" /* webpackChunkName: "component---src-templates-make-appointment-page-tsx" */),
  "component---src-templates-markdown-columns-page-tsx": () => import("./../../../src/templates/markdown-columns-page.tsx" /* webpackChunkName: "component---src-templates-markdown-columns-page-tsx" */),
  "component---src-templates-markdown-hero-page-tsx": () => import("./../../../src/templates/markdown-hero-page.tsx" /* webpackChunkName: "component---src-templates-markdown-hero-page-tsx" */),
  "component---src-templates-markdown-page-tsx": () => import("./../../../src/templates/markdown-page.tsx" /* webpackChunkName: "component---src-templates-markdown-page-tsx" */),
  "component---src-templates-overview-page-tsx": () => import("./../../../src/templates/overview-page.tsx" /* webpackChunkName: "component---src-templates-overview-page-tsx" */),
  "component---src-templates-store-page-tsx": () => import("./../../../src/templates/store-page.tsx" /* webpackChunkName: "component---src-templates-store-page-tsx" */)
}

