import { merge } from "theme-ui";
import { tailwind } from "@theme-ui/presets";

const baseFonts = {
  sans: '"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  serif: 'Georgia,Cambria,"Times New Roman",Times,serif',
  mono: 'Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
};

const theme = merge(tailwind, {
  config: {
    initialColorModeName: "default",
  },
  colors: {
    primary: "#e20074",
    primaryLight: "#ea428d",
    secondary: tailwind.colors.pink[3],
    text: tailwind.colors.gray[8],
    heading: tailwind.colors.black,
    background: "white",
    backgroundOff: tailwind.colors.gray[1],
    divider: tailwind.colors.gray[2],
    textMuted: tailwind.colors.gray[6],
    icon_brightest: tailwind.colors.gray[2],
    icon_darker: tailwind.colors.gray[4],
    icon_darkest: tailwind.colors.gray[6],
    icon_red: tailwind.colors.red[6],
    icon_blue: tailwind.colors.blue[6],
    icon_orange: tailwind.colors.orange[5],
    icon_yellow: tailwind.colors.yellow[5],
    icon_pink: tailwind.colors.pink[5],
    icon_purple: tailwind.colors.purple[6],
    icon_green: tailwind.colors.green[5],
  },
  breakpoints: ["640px", "960px", "1281px", "1600px"],
  sizes: {
    container: 1280,
    containerSmall: 1024,
    containerTiny: 600,
  },
  fontSizes: [14, 16, 18, 20, 24, 32, 42, 56, 72],
  fonts: {
    ...baseFonts,
    body: `Lucida Sans,Lucida Grande,${baseFonts.sans}`,
    heading: `Lucida Sans,Lucida Grande,${baseFonts.sans}`,
    monospace: baseFonts.mono,
  },
  styles: {
    root: {
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
      textRendering: "optimizeLegibility",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      color: "text",
      backgroundColor: "background",
      a: {
        color: "primary",
        textDecoration: "none",
        transition: "all 0.3s ease-in-out",
        opacity: 0.8,
        "&:hover": {
          opacity: 1,
          color: "primary",
          textDecoration: "none",
        },
      },
    },
    p: {
      fontSize: [1, 2],
      letterSpacing: "-0.003em",
      lineHeight: "body",
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: "text",
    },
    blockquote: {
      marginLeft: 0,
      p: {
        fontSize: [2, 3],
        fontWeight: "medium",
        color: "heading",
      },
    },
    h1: {
      fontSize: [5, 5, 6, 7],
      mt: 2,
      mb: 3,
      textShadow: "rgba(255, 255, 255, 0.15) 0px 5px 35px",
      letterSpacing: "wide",
      fontFamily: "heading",
      color: "heading",
      fontWeight: "lighter",
      textAlign: "center",
    },
    h2: {
      fontSize: [4, 4, 5, 6],
      mt: 2,
      mb: 2,
      fontFamily: "heading",
      color: "heading",
      fontWeight: "lighter",
      textAlign: "center",
    },
    h3: {
      fontSize: [3, 3, 4, 5],
      mt: 3,
      fontFamily: "heading",
      color: "heading",
      fontWeight: "lighter",
    },
    h4: {
      fontSize: [2, 2, 3, 4],
      fontFamily: "heading",
      color: "heading",
      fontWeight: "medium",
    },
    h5: {
      fontSize: [2, 2, 3, 4],
      fontFamily: "heading",
      color: "heading",
      fontWeight: "lighter",
    },
    h6: {
      fontSize: [1, 1, 2, 3],
      mb: 2,
      fontFamily: "heading",
      color: "heading",
    },
    ol: {
      listStyle: "decimal",
      mb: "18px",
      mt: "18px",
      fontSize: [1, 2],
      letterSpacing: "-0.003em",
      lineHeight: "body",
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: "text",
    },
    ul: {
      listStyle: "inside",
      p: 0,
      m: 0,
      mb: "18px",
      mt: "18px",
      fontSize: [1, 2],
      letterSpacing: "-0.003em",
      lineHeight: "body",
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: "text",
      textIndent: "-1em",
      paddingLeft: "1em",
    },
    a: {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
    },
    spinner: {
      color: "primary",
    },
  },
  layout: {
    container: {
      maxWidth: "container",
      px: [3, 3, 3, 0],
    },
    csmall: {
      maxWidth: "containerSmall",
    },
    ctiny: {
      maxWidth: "containerTiny",
    },
    themedLightRoot: {
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
      textRendering: "optimizeLegibility",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      color: "gray.1",
      backgroundColor: "background",
      p: {
        fontSize: [1, 2],
        letterSpacing: "-0.003em",
        lineHeight: "body",
        "--baseline-multiplier": 0.179,
        "--x-height-multiplier": 0.35,
        color: "gray.1",
      },
      h1: {
        fontSize: [5, 5, 6, 7],
        mt: 2,
        mb: 3,
        textShadow: "rgba(255, 255, 255, 0.15) 0px 5px 35px",
        letterSpacing: "wide",
        fontFamily: "heading",
        color: "gray.1",
        fontWeight: "medium",
        textAlign: "center",
      },
      h2: {
        fontSize: [4, 4, 5, 6],
        mt: 2,
        mb: 2,
        fontFamily: "heading",
        color: "gray.1",
        fontWeight: "medium",
        textAlign: "center",
      },
      h3: {
        fontSize: [3, 3, 4, 5],
        mt: 3,
        fontFamily: "heading",
        color: "gray.1",
        fontWeight: "medium",
      },
      h4: {
        fontSize: [2, 2, 3, 4],
        fontFamily: "heading",
        color: "gray.1",
        fontWeight: "bold",
      },
      h5: {
        fontSize: [2, 2, 3, 4],
        fontFamily: "heading",
        color: "gray.1",
        fontWeight: "light",
      },
      h6: {
        fontSize: [1, 1, 2, 3],
        mb: 2,
        fontFamily: "heading",
        color: "gray.1",
      },
    },
  },
  links: {
    gray: {
      fontSize: [0, 0, 1],
      opacity: 1.0,
      fontWeight: "normal",
      color: "textMuted",
    },
    lightpink: {
      fontSize: [0, 0, 1],
      opacity: 0.65,
      fontWeight: "normal",
      color: "primary",
    },
  },
  text: {
    default: {
      fontSize: [1, 2],
      letterSpacing: "-0.003em",
      lineHeight: "body",
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: "text",
    },
    smallText: {
      fontSize: [0, 0, 1],
      fontWeight: "normal",
      color: "textMuted",
    },
    smallTextPlus: {
      fontSize: 0,
      fontWeight: "normal",
      color: "textMuted",
    },
    lightHeading: {
      fontSize: [1, 1, 2],
      fontFamily: "heading",
      fontWeight: "normal",
      lineHeight: "heading",
    },
  },
  forms: {
    input: {
      fontSize: [1, 1],
      letterSpacing: "-0.003em",
      fontWeight: "normal",
      lineHeight: "body",
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: "text",
      fontFamily: "body",
    },
    textarea: {
      fontSize: [1, 1],
      letterSpacing: "-0.003em",
      fontWeight: "normal",
      lineHeight: "body",
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: "text",
      fontFamily: "body",
    },
  },
  buttons: {
    cta: {
      color: "primary",
      border: "2px solid",
      borderColor: "primary",
      backgroundColor: "background",
      cursor: "pointer",
      alignSelf: "center",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
      fontSize: [2, 2, 3],
      px: 4,
      py: 3,
      ":hover:enabled": {
        backgroundColor: "primaryLight",
        color: "background",
      },
      ":disabled": {
        borderColor: "secondary",
      },
    },
    default: {
      color: "primary",
      border: "2px solid",
      borderColor: "primary",
      backgroundColor: "background",
      cursor: "pointer",
      alignSelf: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: [1, 1, 2],
      px: 3,
      py: 2,
      ":hover:enabled": {
        backgroundColor: "primaryLight",
        color: "background",
      },
      ":disabled": {
        borderColor: "secondary",
      },
    },
    toggle: {
      color: "background",
      border: "none",
      backgroundColor: "text",
      cursor: "pointer",
      alignSelf: "center",
      px: 3,
      py: 2,
      ml: 3,
    },
    icon: {
      width: 4,
      height: 4,
    },
  },
});

export default theme;
